// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


//require("@rails/activestorage").start()

// import 'jquery'

// window.jQuery = jquery
// window.$ = jquery


import Rails from "@rails/ujs"
Rails.start();


require("jquery-ui-bundle")


require("@nathanvda/cocoon")



import("easy-autocomplete/dist/jquery.easy-autocomplete")




